<template>
  <div class="container-fluid">
    <div v-if="loaded">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h4 id="top">Activity Report Summary</h4>
      </div>

      <div class="row card search-card">
        <div class="rainbow-background"></div>
        <div class="col-md-12 row">
          <div class="col-md-3 align-left">
            <label for="week_starting">Week Starting: </label>
            <datepicker id="week_starting" input-class="date-picker" :disabled-dates="this.state.disabledStartDates" :resetOnOptionsChange="true" label="week_start_date" v-model="selectedStartWeek" placeholder="Select Week Start" format="MM-dd-yyyy" ></datepicker>
          </div>
          <div class="col-md-3 align-left">
            <label for="week_ending">Week Ending: </label>
            <datepicker id="week_ending" input-class="date-picker" :disabled-dates="this.state.disabledEndDates" :resetOnOptionsChange="true" label="week_end_date" v-model="selectedEndWeek" placeholder="Select Week End" format="MM-dd-yyyy" ></datepicker>
          </div>
        </div>
      </div>

      <br>

      <div v-if="data" style="overflow-x:auto;" class="card">
        <table class="card-table table">
          <thead>
          <tr>
            <th>Week of {{ parsedStartWeek }} - {{ parsedEndWeek }}</th>
            <template v-for="(activity, index) in data.activities">
              <th class="smaller-table-font" :key="`${activity}-${index}`">{{ activity }}</th>
            </template>
            <th>Total</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(marketAreaData, marketAreaKey, index) in data.market_areas">
            <tr class="market-area-rows" :key="`${marketAreaKey}-${index}`">
              <th :colspan="data.activities.length + 2">{{ marketAreaKey }}</th>
            </tr>
            <template v-for="coach in marketAreaData.filter((coachData) => { return coachData.total > 0 })">
              <tr :key="coach.uuid">
                <td class="coach-column">{{ coach.name }}</td>
                <template v-for="activity in data.activities">
                  <td :key="`${activity}-${coach.uuid}`">{{ coach.activities[activity] }}</td>
                </template>
                <td class="total-column">{{ coach.total }}</td>
              </tr>
            </template>
          </template>
          <tr class="total-row">
            <td class="coach-column">Total</td>
            <template v-for="(value, key, index) in data.activity_grand_totals">
              <td :key="`${key}-${index}`">{{ value }}</td>
            </template>
            <td class="total-column">{{ data.grand_total }}</td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>
    <div v-else>
      <loading-overlay></loading-overlay>
    </div>
    <button v-on:click="scrollToTop" id="topButton" type="button" class="btn btn-success">TOP</button>
  </div>
</template>

<script>
import { MarketAreaService } from '@/common/services/market_area.service';
import { FilterService } from '@/common/services/filter.service';
import HelperMethods from '@/common/mixins/helper_methods';
import LoadingOverlay from '@/common/components/loading-overlay.vue';
import Datepicker from 'vuejs-datepicker';

export default {
  name: 'MarketAreaReport',
  mixins: [HelperMethods],
  components: {
    LoadingOverlay,
    Datepicker,
  },
  data() {
    return {
      loaded: false,
      loadingText: false,
      reportLoaded: false,
      data: null,
      filters: null,
      selectedStartWeek: null,
      selectedEndWeek: null,
      parsedStartWeek: null,
      parsedEndWeek: null,
      state: {
        disabledStartDates: {
          days: [1, 2, 3, 4, 5, 6],
        },
        disabledEndDates: {
          days: [0, 1, 2, 3, 4, 5],
        },
      },
    };
  },
  created() {
    this.initialize();
  },
  watch: {
    selectedStartWeek(val) {
      if (val) {
        this.getReport();
      }
    },
    selectedEndWeek(val) {
      if (val) {
        this.getReport();
      }
    },
  },
  methods: {
    initialize() {
      this.loaded = true;
    },
    getReport() {
      if (this.selectedStartWeek && this.selectedEndWeek) {
        this.parsedEndWeek = this.selectedEndWeek;
        this.parsedStartWeek = this.selectedStartWeek;

        if (typeof this.parsedStartWeek !== 'string') this.parsedStartWeek = this.getDateString(this.parsedStartWeek);
        if (typeof this.parsedEndWeek !== 'string') this.parsedEndWeek = this.getDateString(this.parsedEndWeek);

        this.filters = FilterService.defaultData();
        const params = FilterService.build({
          page: 1,
          per_page: 3000,
          start_date: this.parsedStartWeek,
          end_date: this.parsedEndWeek,
        }, this.filters);
        MarketAreaService.report(params)
          .then((response) => {
            this.data = response.data;
            this.parsedStartWeek = this.formattedDate(this.selectedStartWeek, 0);
            this.parsedEndWeek = this.formattedDate(this.selectedEndWeek, 0);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .v-select.single.searching:not(.open):not(.loading) input[type=search] {
    opacity: 1;
  }
  .align-left {
    text-align: left;
  }
  .search-card {
    padding-bottom: 30px;

    .instructions {
      padding: 10px;
      border: 1px solid #dee3e7;
      border-radius: 5px;
      margin: 15px auto 15px;
    }
  }

  #topButton {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    border: none;
    outline: none;
    color: white;
    cursor: pointer;
    padding: 10px;
    border-radius: 15px;
    font-size: 15px;

    &:hover {
      background-color: #555;
    }
  }

  .smaller-table-font {
    font-size: 0.6em;
  }

  .market-area-rows {
    text-align: left;
    background-color: lightgray;
  }

  .total-row {
    border-top: 2px solid black;
  }

  .total-column {
    border-left: 2px solid black;
  }

  .coach-column {
    border-right: 1.5px solid black;
  }
</style>
